import React from 'react'
import {
  Row,
  Col,
  Container,
  FancyShow
} from '@kogk/common'
import styles from './SubpageHero.module.scss'
import cn from 'classnames'

export default ({ title, text = undefined }) => {
  const colWidth = cn({ 'col-md-5': text, 'col-12': !text })
  return (
    <div className={styles.container}>
      <FancyShow>
        <Container>
          <Row>
            <Col className={colWidth}>
              <h1>{title}</h1>
            </Col>
            {text && (
              <Col col={{ md: 6 }} offset={{ lg: 1 }}>
                <p className={cn(styles.text, 'parag opacity--08')}>{text}</p>
              </Col>
            )}
          </Row>
        </Container>
      </FancyShow>
    </div>
  )
}
